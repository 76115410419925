<template>
  <div>
    <b-modal centered id="tablePerson" title="Details" size="md" hide-footer>
      <b-table v-if="tablePerson.length > 0" striped responsive :fields="columnsPckage"
               :items="tablePerson" class="mb-0 table-borderless "  id="table-transition-example" :tbody-transition-props="transProps">
        <template v-slot:cell(sales_name)="data1">
          <p class="holdSelection m-0">{{data1.item.sales_target != null ?
              data1.item.sales_target.sales.name:
              'From Branch'}}</p>
        </template>
      </b-table>
      <p v-else class="text-black text-center"> This is Guest User</p>
    </b-modal>
    <b-row>

      <!-- start filter -->
      <b-container fluid class="filter">
        <div class="iq-card p-1">
          <div class="row">
            <div class="col-md-3 my-1">
              <input type="text" placeholder="search by member name" class="form-control" v-model="filter.name" @input="getAllMembers()" >
            </div>
            <div class="col-md-3 my-1">
              <select class="form-control" v-model="filter.type" @change="getAllMembers()">
                <option selected disabled value="">select Type...</option>
                <option value="guest">guest</option>
                <option value="member">member</option>
              </select>
            </div>
            <div class="col-md-3 my-1">
              <select class="form-control"  v-model="filter.project" @change="getAllMembers()">
                <option selected disabled value="">select Project...</option>
                <option v-for="(i, key) in getUserProjects" :key="key" :value="i.id">
                  <span>{{i.en_title}}</span>
                </option>
              </select>
            </div>
            <div class="col-md-3 my-1" v-if="filter.project">
              <b-form-checkbox v-model="filter.expired" @change="getAllMembers">Expired</b-form-checkbox>
            </div>
            <div class="col-md-12 my-1">
              <date-range-picker
                  class="w-100"
                  ref="picker"
                  opens=false
                  :timePicker=false
                  :timePicker24Hour=false
                  :showWeekNumbers=true
                  :showDropdowns=true
                  format='mm/dd/yyyy'
                  direction="center"
                  :autoApply=true
                  v-model="dateRange"
                  :linkedCalendars=true
                  customRangeLabel= 'Custom Range'
                  @update="getAllMembers"
              >
              </date-range-picker>
            </div>
            <div class="col-md-1 d-flex justify-content-center align-items-center" v-if="filter.name || filter.type
            || dateRange.startDate || dateRange.endDate|| filter.project|| filter.expired">
              <span class="text-danger cursor-pointer" @click="resetFilter()">Clear All</span>
            </div>
          </div>
        </div>
      </b-container>
      <!-- end filter -->

      <!--start content-->
      <b-col lg="12">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">list Of Members</h4>
          </template>
          <template v-slot:body>
            <div v-if="loadTable" class="text-center">
              <b-spinner small type="grow"></b-spinner>
              Loading...
            </div>
            <b-table v-else sort-icon-left primary-key="code" striped responsive :fields="columns" :items="allMembers" class="mb-0 table-borderless "  id="table-transition-example" :tbody-transition-props="transProps">
              <template v-slot:cell(name)="data">
                <router-link class="linkedReport" :to="{name: 'profile', params:{ id : data.item.id }}"><b-badge variant="primary">{{ data.item.name }}</b-badge></router-link>
              </template>
              <template v-slot:cell(image)="data1">
               <img v-if="data1.item.image" :src="data1.item.profile" class="avatar-50 rounded-circle"/>
                <img v-else src="../../../../assets/images/user/user.jpg" class="avatar-50 rounded-circle"/>
              </template>
              <template v-slot:cell(type)="data1">
                <span v-if="data1.item.type == 'guest'" class="text-black">Guest</span>
                <span v-if="data1.item.type == 'member'" class="text-success">Member</span>
                <span v-if="data1.item.type == 'expired'" class="text-danger">Expired</span>
              </template>
              <template v-slot:cell(phone)="data1">
                <p class="holdSelection m-0" v-if="data1.item.id==showPhone">{{data1.item.phone}}</p>
                <b-button v-else variant=" iq-bg-success mr-0 mb-0" size="sm" :id="data1.item.id" @click="showPhone = data1.item.id" ><i class="las la-mobile"></i>Show Phone</b-button>
              </template>
              <template v-slot:cell(active)="data">
                <div class="custom-control custom-switch custom-switch-text custom-control-inline custom-switch-color" >
                  <div class="custom-switch-inner">
                    <input type="checkbox" class="custom-control-input bg-primary" v-model="data.item.is_active" :id="'customSwitch-11'+data.item.id"   @change="changeMemberStatus(data.item.id)" >
                    <label class="custom-control-label" :for="'customSwitch-11'+data.item.id" data-on-label="on" data-off-label="off">
                    </label>
                  </div>
                </div>
              </template>
              <template v-slot:cell(package)="data">
                <b-button variant=" iq-bg-success mr-1 mb-1" size="sm" @click="showTablePackageDetails(data.item.member_package_with_out_scope)"><i
                    class="las la-eye"></i></b-button>
              </template>
            </b-table>
            <b-pagination v-model="pagination.currentPage"
                          class="mt-3"
                          :total-rows="pagination.total"
                          align="right"
                          :per-page="pagination.per_page"
                          aria-controls="my-table"
                          @input="getAllMembers"
            ></b-pagination>
          </template>
        </iq-card>
      </b-col>
      <!--end content-->

    </b-row>
  </div>
</template>
<script>
import { mapGetters } from 'vuex'

import { core } from '../../../../config/pluginInit'
import DateRangePicker from 'vue2-daterange-picker'
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'
import services from '../../services/reports'
export default {
  name: 'allMemberReports',

  data () {
    return {
      showPhone: '',
      transProps: {
        // Transition name
        name: 'flip-list'
      },
      projects: [],
      filter: {
        name: '',
        type: '',
        project: '',
        expired: 0
      },
      dateRange: {
        startDate: '',
        endDate: ''
      },
      pagination: {
        currentPage: 1,
        per_page: 10,
        total: 6
      },
      loadTable: true,
      columns: [
        { label: 'Image', key: 'image', class: 'text-left' },
        { label: 'Name', key: 'name', class: 'text-left', sortable: true },
        { label: 'Phone', key: 'phone', class: 'text-left', sortable: true },
        { label: 'Code', key: 'lead.id', class: 'text-left', sortable: true },
        { label: 'Membership_id', key: 'membership_id', class: 'text-left', sortable: true },
        { label: 'Packages', key: 'package', class: 'text-left', sortable: true },
        { label: 'Type', key: 'type', class: 'text-left', sortable: true },
        { label: 'Active', key: 'active', class: 'text-left', sortable: true }
      ],
      allMembers: [],
      columnsPckage: [
        { label: 'Name Of Package', key: 'package_title', class: 'text-left' },
        { label: 'Sales', key: 'sales_name', class: 'text-left' }
      ],
      tablePerson: []
    }
  },
  computed: {
    ...mapGetters(['getUserProjects'])
  },
  methods: {
    showTablePackageDetails (table) {
      this.$bvModal.show('tablePerson')
      this.tablePerson = table
    },
    changeMemberStatus (id) {
      this.$store.dispatch('changeMemberStatus', id).then(res => {
        core.showSnackbar('success', res.data.message)
      }).catch(err => {
        if (err.response.data.errors) {
          for (const [key, value] of Object.entries(err.response.data.errors)) {
            this.$refs[key].setErrors(value)
          }
        } else if (err.response.data.error) {
          core.showSnackbar('error', err.response.data.error)
        }
      })
    },
    resetFilter () {
      this.filter = {
        name: '',
        type: '',
        project: '',
        expired: 0
      }
      this.dateRange = {
        startDate: '',
        endDate: ''
      }
      this.getAllMembers()
    },
    getAllMembers () {
      this.loadTable = true
      services.getAllMembers({
        pageNumber: this.pagination.currentPage,
        dateFrom: this.dateRange.startDate ? new
        Date(this.dateRange.startDate).toJSON().slice(0, 10) : '',
        dateTo: this.dateRange.endDate ? new
        Date(this.dateRange.endDate).toJSON().slice(0, 10) : '',
        name: this.filter.name,
        type: this.filter.type,
        projectId: this.filter.project,
        expired: this.filter.expired,
        report: 1
      }).then(res => {
        this.allMembers = res.data.data
        this.pagination.currentPage = res.data.meta.current_page
        this.pagination.per_page = res.data.meta.per_page
        this.pagination.total = res.data.meta.total
      }).finally(() => {
        this.loadTable = false
      })
    }
  },
  components: {
    DateRangePicker
  },
  mounted () {
    core.index()
  },
  created () {
    this.getAllMembers()
  }
}
</script>
<style>
.nav-link.router-link-exact-active.router-link-active{
  background: var(--iq-primary) !important;
  color: #fff !important;
}
.colorSpan{
  width: 20px;
  height: 20px;
}
[dir=ltr][mode=light] .nav-tabs {
  background: #fff !important;
  border-radius: 10px !important;

  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden;
}
th{
  white-space: pre !important;
  vertical-align: middle !important;
}
td{
  white-space: pre !important;
  vertical-align: middle !important;
}
</style>
